/**
 * 接口名称对应表
 */

/// 获取爆料目录列表
export const getBaoliaoCategory = "content/listBaoliaoCategory"

/// 获取羊毛爆料列表
export const getBaoliaoList = "content/listYangmaoBaoliao"

/// 获取限时秒杀时间段
export const getMiaoshaTimePeriods = "miaosha/listTime"

/// 获取限时秒杀某时间段商品列表
export const getMiaoshaGoods = "miaosha/listGoods"

/// 获取大淘客分类列表
export const getDtkCategory = "meiwu/listDtkCategory"

/// 获取大淘客商品列表
export const getDtkGoods = "meiwu/listDtkCategoryGoods"

/// 获取商品详情
export const getGoods = "mark/getGoods"