export const devDevHost = "https://preview.meiwulist.com";
export const releaseDevHost = "https://api.meiwulist.com";

export const apiHost = "api.meiwulist.com";
export const apiPreviewHost = "preview.meiwulist.com:8080"

export const baseURL =  location.protocol + "//" + (location.host.indexOf('localhost') > -1 ? apiPreviewHost : apiHost) + "/api_service/api/v1/"
  // process.env.NODE_ENV === "development"
  //   ? devDevHost
  //   : process.env.VUE_APP_API === "build:dev"
  //   ? devDevHost
  //   : releaseDevHost;
// 代理参数配置于 ./vue.cinfig.js

export default baseURL;
